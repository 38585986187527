import axios from '../axios.instance'

export default class HotelService {
    async getRestaurants(){
        try {
            const res = await axios.get('/restaurant');
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    //USE TO GET Restaurant Name IN CONTRACT UPDATE FORM
    async getRestaurantName(id){
        try {
            const res = await axios.get('/restaurant/getrestaurantname', {params: { restaurantid: id }});
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async addRestaurant(data) {
        try {
            if(data != ''){
                const res = await axios.post('/restaurant', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateRestaurant(restaurant_id, data){
        try {
            if(data != '') {
                const res = await axios.put('/restaurant/'+restaurant_id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteRestaurant(restaurant_id){
        try {
            if(restaurant_id != 0) {
                const res = await axios.delete('/restaurant/'+restaurant_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async filterRestaurant(provinceFilter) {
        try {
            const res = await axios.post('/restaurant/filter-restaurant', {provinceFilter});
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async searchRestaurant(data) {
        try {
            const res = await axios.get('/restaurant/search/', {params: {restname: data}})
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    //RESTAURANT CONTRACT
    async getContract(rest_id) {
        try {
            const res = await axios.get('/restaurantcontract/', {params: {restaurantid: rest_id}})
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async addRestaurantContract(data) {
        try {
            if(data != ''){
                const res = await axios.post('/restaurantcontract/add', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateRestaurantContract(contract_id, data) {
        try {
            if(data != ''){
                const res = await axios.put('/restaurantcontract/update/'+contract_id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteRestaurantContract(contract_id) {
        try {
            if(contract_id != 0){
                const res = await axios.delete('/restaurantcontract/delete/'+contract_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //RESTAURANT CONTRACT PERIOD
    async getRestaurantContractPeriod(contract_id) {
        try {
            const res = await axios.get('/restaurantcontractperiod/', {params: {contractid: contract_id}})
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async addRestContractPeriod(data) {
        try {
            if(data != ''){
                const res = await axios.post('/restaurantcontractperiod/add', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateRestContractPeriod(id, data) {
        try {
            if(data != ''){
                const res = await axios.put('/restaurantcontractperiod/update/'+id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteRestContractPeriod(id) {
        try {
            if(id != 0){
                const res = await axios.delete('/restaurantcontractperiod/delete/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //RESTAURANT MENU PRICE
    async getMenu(restaurantID) {
        try {
            if(restaurantID != 0) {
                const res = await axios.get('/restaurant/getmenu', {params: {restid: restaurantID}})
                return res.data;
            }            
        } catch (error) {
            return error.response.data
        }
    }

    async getMenuPrice(period_id) {
        try {
            const res = await axios.get('/restaurantmenuprice', {params: {periodid: period_id}})
            return res.data;
            
        } catch (error) {
            return error.response.data
        }
    }

    async addMenuPrice(data) {
        try {
            if(data != '') {
                const res = await axios.post('/restaurantmenuprice/add', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateMenuPrice(menuPriceID, data) {
        try {
            if(data != '') {
                const res = await axios.put('/restaurantmenuprice/update/'+menuPriceID, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteMenuPrice(menuPriceID) {
        try {
            if(menuPriceID != 0) {
                const res = await axios.delete('/restaurantmenuprice/delete/'+menuPriceID)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteMenuName(menuID) {
        try {
            if(menuID != 0) {
                const res = await axios.delete('/restaurant/deletemenu/'+menuID)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //RESTAURANT MENU DISH
    async getMenuDish(menuID) {
        try {
            if(menuID != 0) {
                const res = await axios.get('/restaurantmenudish/', { params: {menuid: menuID}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async addMenuDish(data) {
        try {
            if(data != '') {
                const res = await axios.post('/restaurantmenudish/add', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteMenuDish(menuDishID) {
        try {
            if(menuDishID != 0) {
                const res = await axios.delete('/restaurantmenudish/delete/'+menuDishID)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //RESTAURANT CONTRACT POLICY
    async getContractPolicy(policyType, periodID) {
        try {
            if(periodID != 0) {
                const res = await axios.get('/restaurantpolicy/', { params: {policy_type: policyType, periodid: periodID}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async saveChildrenPolicy(data) {
        try {
            if(data != '') {
                const res = await axios.post('/restaurantpolicy/', { data })
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}